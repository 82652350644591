"use client";

import { useCallback, useEffect } from "react";

import { useProfileContext } from "@/providers";

declare global {
  interface Window {
    dataLayer: any | undefined;
    gtag: any | undefined;
    jivo_onLoadCallback: any | undefined;
    jivo_destroy: any | undefined;
    jivo_init: any | undefined;
  }
}

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const profile = useProfileContext();

  const handleLoad = useCallback(() => {
    if (!profile) {
      return null;
    }
    window.jivo_api?.setContactInfo({
      email: profile?.email,
    });
    window.jivo_api?.setCustomData([
      {
        content: profile?.id,
        key: "userId",
      },
      {
        content: profile?.email,
        key: "email",
      },
    ]);
  }, [profile]);

  useEffect(() => {
    window.addEventListener("jivoInit", handleLoad);

    return () => {
      window.removeEventListener("jivoInit", handleLoad);
    };
  });

  return (
    <>
      {/* {profile?.paymentCount! > 0 ? (
        <img
          src="https://tsyndicate.com/api/v1/retargeting/set/1d88e252-e85d-4724-86fb-ea99e8768193?reg=1&pay=1&view=1"
          height="1"
          width="1"
          style={{ display: "none" }}
          alt=""
        />
      ) : profile ? (
        <img
          src="https://tsyndicate.com/api/v1/retargeting/set/1d88e252-e85d-4724-86fb-ea99e8768193?reg=1&pay=0&view=1"
          height="1"
          width="1"
          style={{ display: "none" }}
          alt=""
        />
      ) : (
        <img
          src="https://tsyndicate.com/api/v1/retargeting/set/1d88e252-e85d-4724-86fb-ea99e8768193?reg=0&pay=0&view=1"
          height="1"
          width="1"
          style={{ display: "none" }}
          alt=""
        />
      )} */}
      {children}
    </>
  );
}
